<template>
  <div>
    <v-flex
      v-if="userTickets.length <= 0 && !editPost"
      xs12
      class="d-flex justify-center w-100"
    >
      <v-switch
        v-model="showSoldItems"
        class="mt-0"
        hide-details
        :label="$vuetify.lang.t('$vuetify.posts.showHistory')"
      ></v-switch>
    </v-flex>
    <div
      v-if="(loading.state === false && !itemInAction) || itemInAction"
      :class="
        loading.state === false && userTickets.length <= 0
          ? 'd-flex align-center justify-center'
          : ''
      "
      class="overflow-y-auto"
    >
      <v-flex
        v-if="userTickets.length > 0"
        xs12
        class="d-flex justify-center w-100"
      >
        <v-switch
          v-model="showSoldItems"
          class="mt-0"
          hide-details
          :label="this.$vuetify.lang.t('$vuetify.posts.showHistory')"
        ></v-switch>
      </v-flex>
      <v-list class="transparent px-2">
        <v-list-item
          v-for="ticket in userTickets"
          :key="ticket.id"
          class="flex-wrap pa-0 transparent radius-10 mb-6 rounded-10"
          style="
          background: #FFF9F9 !important; box-shadow: 0px 2px 8px rgba(1, 92, 137, 0.2) !important;"
        >
          <div
            class="d-flex flex-wrap pa-3 pl-5 pl-md-9 pt-md-6 pt-1 pb-0 w-100"
          >
            <v-row v-if="$vuetify.breakpoint.xsOnly" class="mt-5 mb-4 mb-md-0">
              <v-col cols="6" class="pa-0">
                <span
                  class="d-flex align-center primary--text text--darken-2 subtitle-2"
                >
                  <v-icon class="primary--text text--darken-2 mr-1" small>
                    mdi-calendar
                  </v-icon>
                  {{ fullDateMonth(ticket.event.startDate) }}
                </span>
              </v-col>
              <v-col class="pa-0">
                <span
                  class="d-flex align-center primary--text text--darken-2 subtitle-2"
                >
                  <v-icon class="primary--text text--darken-2 mr-1" small>
                    mdi-map-marker
                  </v-icon>
                  {{ ticket.event.venue.split(',')[0] }}
                </span>
              </v-col>
            </v-row>
            <v-list-item-icon
              class="d-flex justify-center mt-0 mt-md-4 mb-0 mr-0 mr-md-8"
            >
              <v-img
                class="primary-shadow main-border-radius align-center"
                :src="
                  ticket.globalEventId
                    ? `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${ticket.globalEventId}h4234h.jpg?alt=media`
                    : require('@/assets/img/placeholder_2.png')
                "
                :lazy-src="
                  `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${ticket.globalEventId}h4234h.jpg?alt=media`
                "
                :width="$vuetify.breakpoint.smAndUp ? '150px' : '88px'"
                gradient="rgba(100,115,201,.33), rgba(25,32,72,.7)"
              >
                <v-card-title
                  class="justify-center pa-0 pa-md-3 display-4 font-weight-bold white--text"
                  :style="
                    $vuetify.breakpoint.smAndUp
                      ? 'font-size:144px !important'
                      : 'font-size:80px !important'
                  "
                >
                  {{
                    ticket.subTickets
                      ? !ticket.subTickets.length
                        ? '1'
                        : ticket.subTickets.length
                      : 1
                  }}
                </v-card-title>
                <div class="d-flex justify-center align-end">
                  <span
                    class="headline font-weight-bold white--text"
                    :class="{
                      headline: $vuetify.breakpoint.smAndUp,
                      'subtitle-1': $vuetify.breakpoint.xsOnly
                    }"
                    >PILETIT</span
                  >
                </div>
              </v-img>
            </v-list-item-icon>
            <v-list-item-content
              :style="$vuetify.breakpoint.smAndUp ? 'min-width:200px' : ''"
              class="ml-4 ml-sm-0"
            >
              <v-row v-if="$vuetify.breakpoint.smAndUp" class="mb-5">
                <v-col cols="3" class="pa-0">
                  <span
                    class="d-flex align-center primary--text text--darken-2 font-18 font-weight-medium"
                  >
                    <v-icon class="primary--text text--darken-2 mr-1" small
                      >mdi-calendar</v-icon
                    >
                    {{ fullDateMonth(ticket.event.startDate) }}
                  </span>
                </v-col>
                <v-col class="pa-0">
                  <span
                    class="d-flex align-center primary--text text--darken-2 font-18 font-weight-medium"
                  >
                    <v-icon class="primary--text text--darken-2 mr-1" small
                      >mdi-map-marker</v-icon
                    >
                    {{ ticket.event.venue.split(',')[0] }}
                  </span>
                </v-col>
              </v-row>
              <span
                class="d-flex align-center pb-2 font-18 font-weight-bold"
                :class="{ headline: $vuetify.breakpoint.smAndUp }"
              >
                {{ shortenTheTitle(ticket.event.title) }}
              </span>
              <v-expansion-panels
                v-if="$vuetify.breakpoint.smAndUp"
                multiple
                flat
                tile
                class="w-100"
              >
                <v-expansion-panel style="background-color:rgba(0,0,0,0)">
                  <v-expansion-panel-header
                    class="pa-0 flex-row-reverse primary--text w-auto"
                  >
                    Piletid
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list
                      class="mt-3 d-flex flex-wrap pa-0"
                      style="background-color:unset"
                      :class="{
                        'col 6': ticket.subTickets && !ticket.subTickets.length
                      }"
                    >
                      <div
                        v-if="ticket.subTickets && ticket.subTickets.length"
                        class="d-flex flex-wrap w-100"
                      >
                        <ticket-subticket-item
                          v-for="(sub, index) in ticket.subTickets"
                          :key="index"
                          :subticket="{ ...sub, index }"
                        >
                        </ticket-subticket-item>
                      </div>
                      <div v-else class="d-flex flex-wrap">
                        <ticket-subticket-item :subticket="ticket">
                        </ticket-subticket-item>
                      </div>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-list-item-content>
          </div>
          <v-expansion-panels
            v-if="$vuetify.breakpoint.xsOnly"
            multiple
            flat
            tile
            class="w-auto pl-5"
          >
            <v-expansion-panel style="background-color:rgba(0,0,0,0)">
              <v-expansion-panel-header
                class="pa-0 flex-row-reverse primary--text w-auto"
                >Piletid
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list
                  class="mt-3 d-flex flex-wrap pa-0"
                  style="background-color:unset"
                  :class="{
                    'col 6': ticket.subTickets && !ticket.subTickets.length
                  }"
                >
                  <div
                    v-if="ticket.subTickets && ticket.subTickets.length"
                    class="d-flex flex-wrap"
                  >
                    <ticket-subticket-item
                      v-for="(sub, index) in ticket.subTickets"
                      :key="index"
                      :subticket="{ ...sub, index }"
                    >
                    </ticket-subticket-item>
                  </div>
                  <div v-else class="d-flex flex-wrap">
                    <ticket-subticket-item :subticket="ticket">
                    </ticket-subticket-item>
                  </div>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-list-item-action
            v-if="
              ticket.status === 'active' &&
                ticket.status !== 'inactive' &&
                ticket.status !== 'deactivated' &&
                ticket.status !== 'sold'
            "
            class="w-100 row flex-wrap d-flex radius-10 mt-3"
            style="background:rgba(255,255,255,1); max-height: 100px; border-radius: 0px 00px 10px 10px;"
          >
            <v-col cols="5" class="col-md-8 pl-5 pl-md-11 align-center d-flex">
              <div class="primary--text mt-4 d-flex">
                <span
                  v-if="$vuetify.breakpoint.smAndUp"
                  class="subtitle-2 mr-7 align-self-center"
                  style="font-weight:600"
                >
                  Hind:
                </span>
                <span
                  v-if="!editPost && ticket.id === itemInAction"
                  class="display-1 price font-weight-bold"
                >
                  {{ ticket.price }}
                </span>
                <span
                  v-if="editPost && ticket.id !== itemInAction"
                  class="display-1 price font-weight-bold"
                >
                  {{ ticket.price }}
                </span>
                <span
                  v-if="!editPost && ticket.id !== itemInAction"
                  class="display-1 price font-weight-bold"
                >
                  {{ ticket.price }}
                </span>
                <v-text-field
                  v-show="editPost && ticket.id === itemInAction"
                  v-model="editedPrice"
                  name="price"
                  label="Price"
                  solo
                  :value="ticket.price"
                  style="width: 120px;"
                  hide-details
                >
                  <template v-slot:append>
                    <v-fade-transition leave-absolute>
                      <v-btn
                        icon
                        color="success"
                        text
                        @click.stop="editPrice(ticket)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </v-fade-transition>
                  </template>
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="7" class="col-md-4 align-center d-flex justify-end">
              <v-row
                class="justify-end"
                style="flex: 0 !important; margin: 0 !important"
              >
                <v-col cols="12" class="pa-0 d-flex justify-end">
                  <v-btn
                    v-if="!ticket.isSold"
                    :loading="
                      ticket.id === itemInAction &&
                        actionType === 'markingAsSold'
                    "
                    text
                    color="accent"
                    class="d-block"
                    @click="markAsSold(ticket.id)"
                  >
                    {{ $vuetify.lang.t('$vuetify.posts.markAsSold') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pa-0 d-flex justify-center">
                  <v-btn icon color="primary" @click="didClickEditPost(ticket)">
                    <v-icon>{{
                      editPost && ticket.id === itemInAction
                        ? 'mdi-close'
                        : 'mdi-pencil'
                    }}</v-icon>
                  </v-btn>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="
                          ticket.id === itemInAction &&
                            actionType === 'deletingPost'
                        "
                        icon
                        color="error"
                        v-on="on"
                        @click="openDeleteAlert(ticket.id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.posts.delete') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="!ticket.isSold"
                        :loading="
                          ticket.id === itemInAction &&
                            actionType === 'deactivatingPost'
                        "
                        icon
                        color="secondary"
                        v-on="on"
                        @click="deactivatePost(ticket.id)"
                      >
                        <v-icon>mdi-layers</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.posts.deactivate')
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-list-item-action>
          <v-fade-transition>
            <v-overlay
              v-if="
                ticket.status !== 'active' && ticket.status == 'deactivated'
              "
              absolute
              class="text-center post-overlay"
            >
              <span class="title d-block mb-3">
                {{ ticket.title }}
              </span>
              <v-btn
                :loading="
                  ticket.id === itemInAction && actionType === 'activatingPost'
                "
                color="accent"
                @click="activatePost(ticket.id)"
              >
                {{ $vuetify.lang.t('$vuetify.posts.activateThisItem') }}
              </v-btn>
            </v-overlay>
          </v-fade-transition>
          <v-fade-transition>
            <v-overlay
              v-if="ticket.id === itemInAction && deleteAlert"
              absolute
              class="text-center post-overlay"
            >
              <span class="title d-block mb-2">
                {{ $vuetify.lang.t('$vuetify.posts.doYouReallyWantToDelete') }}
              </span>
              <small class="d-block mb-3">
                {{ $vuetify.lang.t('$vuetify.posts.thisCanNotBeUndone') }}
              </small>
              <v-btn color="primary" class="mr-3" @click="closeDeleteAlert()">
                {{ $vuetify.lang.t('$vuetify.general.cancel') }}
              </v-btn>
              <v-btn
                :loading="
                  ticket.id === itemInAction && actionType === 'deletingPost'
                "
                color="error"
                @click="deleteTicket(ticket.id)"
              >
                <v-icon>mdi-delete</v-icon>
                &nbsp;{{ $vuetify.lang.t('$vuetify.posts.delete') }}
              </v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-list-item>
      </v-list>
      <v-fade-transition hide-on-leave>
        <empty-state-card
          v-if="loading.state === false && userTickets.length <= 0"
          name="chats-empty"
          :title="
            !showSoldItems
              ? $vuetify.lang.t('$vuetify.emptyState.noUserTicketsTitle1')
              : $vuetify.lang.t('$vuetify.emptyState.noUserTicketsTitle2')
          "
          :icon="'mdi-format-list-text'"
          color="transparent"
          :message="
            !showSoldItems
              ? $vuetify.lang.t('$vuetify.emptyState.noUserTicketsMessage1')
              : $vuetify.lang.t('$vuetify.emptyState.noUserTicketsMessage2')
          "
          :action-text="
            !showSoldItems
              ? $vuetify.lang.t('$vuetify.emptyState.addTicket')
              : ''
          "
          class="empty-state"
          @on-cta-click="openTicketAddingView()"
        />
      </v-fade-transition>
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}
        <v-btn color="accent" text @click="snackbar = false">
          Sulge
        </v-btn>
      </v-snackbar>
    </div>
    <loader
      v-show="loading.state === true && userTickets.length <= 0"
      :min-height="60"
      :max-height="60"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import FormatDate from '@/mixins/FormatDate'
import FormatTitle from '@/mixins/FormatTitle'
import TicketSubticketItem from '@/components/Event/TicketSubticketItem'

export default {
  components: {
    TicketSubticketItem,
    EmptyStateCard: () =>
      import(
        /* webpackChunkName: "EmptyStateCard" */ '@/components/EmptyStateCard'
      ),
    Loader: () => import(/* webpackChunkName: "Loader" */ '@/components/Loader')
  },
  mixins: [FormatDate, FormatTitle],
  data() {
    return {
      deactivatingPost: false,
      activatingPost: false,
      deletingPost: false,
      itemInAction: '',
      actionType: '',
      deleteAlert: false,
      snackbar: false,
      snackbarText: '',
      markAsSoldDialog: false,
      editPost: false,
      editedPrice: 0
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
    ...mapGetters('events', ['userTickets']),
    ...mapGetters('authentication', ['user']),
    showSoldItems: {
      get() {
        return this.$store.getters['events/showSoldItems']
      },
      set(value) {
        this.$store.commit('events/setShowSoldItems', value)
      }
    }
  },
  mounted() {
    this.getUserTickets()
  },
  methods: {
    ...mapActions('events', ['getUserTickets']),
    ...mapMutations('app', ['setSearchString']),
    openTicketAddingView() {
      this.$router.replace('lisa-pilet')
    },
    openDeleteAlert(id) {
      this.itemInAction = id
      this.deleteAlert = true
    },
    closeDeleteAlert() {
      this.itemInAction = ''
      this.deleteAlert = false
    },
    deleteTicket(id) {
      this.itemInAction = id
      this.actionType = 'deletingPost'
      this.$store
        .dispatch('events/deleteTicket', id)
        .then(() => {
          this.actionType = ''
          this.itemInAction = ''
          this.deleteAlert = false
        })
        .catch(() => {
          this.actionType = ''
          this.itemInAction = ''
        })
    },
    deactivatePost(id) {
      this.deleteAlert = false
      this.itemInAction = id
      this.actionType = 'deactivatingPost'
      this.$store
        .dispatch('events/deactivateTicket', id)
        .then(() => {
          this.actionType = ''
          this.itemInAction = ''
        })
        .catch(() => {
          this.actionType = ''
          this.itemInAction = ''
        })
    },
    activatePost(id) {
      this.deleteAlert = false
      this.itemInAction = id
      this.actionType = 'activatingPost'
      this.$store
        .dispatch('events/activateTicket', id)
        .then(() => {
          this.actionType = ''
          this.itemInAction = ''
        })
        .catch(() => {
          this.actionType = ''
          this.itemInAction = ''
        })
    },
    async markAsSold(ticketId) {
      this.itemInAction = ticketId
      this.actionType = 'markingAsSold'
      await this.$store
        .dispatch('events/markAsSold', ticketId)
        .then(() => {
          this.snackbar = true
          this.snackbarText = 'Pilet edukalt märgitud müüduks!'
          this.actionType = ''
          this.itemInAction = ''
        })
        .catch(() => {
          this.actionType = ''
          this.itemInAction = ''
          this.snackbar = true
          this.snackbarText = 'Something went wong. Please try again later'
        })
    },
    // async openMarkAsSoldDialog(item) {
    //   this.markAsSoldDialog = true
    //   this.itemInAction = item.id
    //   this.actionType = 'markingAsSold'
    //   await this.$store
    //     .dispatch('chats/getUserContacts', item)
    //     .then(resolved => {
    //       if (resolved === true) {
    //         this.actionType = ''
    //       }
    //     })
    // },
    didClickEditPost(item) {
      this.itemInAction = item.id
      this.editPost = !this.editPost
    },
    toDate(dateString) {
      const date = new Date(dateString)
      return `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() +
        1}`.slice(-2)}.${date.getFullYear()}`
    },
    async editPrice(ticket) {
      this.itemInAction = ticket.id
      if (
        this.editedPrice !== 0 &&
        this.editedPrice !== '' &&
        this.editedPrice !== ticket.currentPrice
      ) {
        this.$store.commit('events/updateTicketPrice', {
          ticketId: ticket.id,
          // eslint-disable-next-line no-restricted-globals
          newPrice: isNaN(Number(this.editedPrice))
            ? false
            : Number(this.editedPrice)
        })
        await this.$store.dispatch('events/createTicket', {
          data: {
            id: ticket.id,
            // eslint-disable-next-line no-restricted-globals
            price: isNaN(Number(this.editedPrice))
              ? false
              : Number(this.editedPrice)
          },
          requestType: 'update'
        })
        this.editPost = false
      }
    }
  }
}
</script>

<style scoped>
.post-overlay.v-overlay.v-overlay--active.theme--dark {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.theme--light.v-card {
  border-radius: 16px !important;
}
.price:after {
  content: '€';
  font-size: 20px;
  position: absolute;
  margin-top: -8px;
}
</style>
